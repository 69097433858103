import * as React from "react"
import { Heading } from "gatsby-interface"
import { StarterCard } from "./starter-card"

const sectionCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  position: `relative`,
  width: `100%`,
  gap: theme.space[7],
  paddingTop: theme.space[7],
  marginTop: theme.space[7],
})

const listCss = theme => ({
  display: "grid",
  gap: theme.space[7],
  listStyle: `none`,
  margin: 0,

  [theme.mediaQueries.phablet]: {
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
  },
  [theme.mediaQueries.desktop]: {
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
  },
  [theme.mediaQueries.hd]: {
    gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
  },
})

const liCss = _ => ({
  margin: 0,
})

const StarterCardList = ({ starters, heading }) => {
  return (
    <section css={sectionCss}>
      <div
        id={heading.replace(" ", "-")}
        css={theme => ({
          position: `absolute`,
          top: `-${theme.space[12]}`,
          [theme.mediaQueries.phablet]: {
            top: `-${theme.space[7]}`,
          },
        })}
      ></div>
      <Heading as="h3">{heading}</Heading>
      <ul css={listCss}>
        {starters.map(starter => {
          return (
            <li key={starter.id} css={liCss}>
              <StarterCard starter={starter} />
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default StarterCardList
