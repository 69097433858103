export const focusStyle = theme => ({
  outline: 0,
  boxShadow: `0 0 0 2px ${theme.colors.purple[60]}`,
})

// form elements
export const formInputFocus = theme => ({
  borderColor: theme.colors.white,
  ...focusStyle(theme),
})

export const formInput = theme => ({
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.colors.grey[30]}`,
  borderRadius: 2,
  display: `block`,
  fontFamily: theme.fonts.body,
  fontSize: 2,
  fontWeight: theme.fontWeights.body,
  lineHeight: `2.25rem`,
  py: 0,
  px: 2,
  transition: `box-shadow ${theme.transitions.speed.default} ${theme.transitions.curve.default}`,
  verticalAlign: `middle`,
  width: `100%`,
  "::placeholder": {
    color: theme.colors.grey[50],
    opacity: 1,
  },
  "&:focus": {
    ...formInputFocus,
  },
  "&:disabled": {
    cursor: `not-allowed`,
    opacity: `0.5`,
  },
})

export const themedInputFocus = theme => ({
  bg: theme.colors.white,
  boxShadow: `0 0 0 2px ${theme.colors.purple[30]}`,
  outline: 0,
  width: `100%`,
})

export const themedInput = theme => ({
  ...formInput(theme),
  appearance: `none`,
  bg: theme.colors.grey[10],
  border: 0,
  color: `text`,
  overflow: `hidden`,
  px: 3,
  ":focus": {
    ...themedInputFocus(theme),
  },
  "::placeholder": {
    color: theme.colors.grey[60],
  },
})
