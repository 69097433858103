import * as React from "react"

import StarterCardList from "./starter-card-list"

const popularList = [
  "gatsbyjs/gatsby-starter-contentful-homepage",
  "gatsbyjs/gatsby-starter-datocms-homepage",
  "gatsbyjs/gatsby-starter-drupal-homepage",
  "gatsbyjs/gatsby-starter-wordpress-homepage",
  "gatsbyjs/gatsby-starter-sanity-homepage",
  "gatsbyjs/gatsby-starter-shopify",
  "gatsbyjs/gatsby-starter-blog",
  "LekoArts/gatsby-starter-portfolio-cara",
]

const PopularStarters = ({ data }) => {
  const { nodes: starters } = data
  const filteredStarters = React.useMemo(() => {
    return popularList.reduce((acc, title) => {
      const starter = starters.find(s => s.title === title)
      if (starter) {
        acc.push(starter)
      }
      return acc
    }, [])
  }, [popularList, starters])
  return <StarterCardList starters={filteredStarters} heading="Popular" />
}

export default PopularStarters
