/** @jsx jsx */
import { jsx } from "theme-ui"
import { get } from "lodash-es"
import { showcaseItem } from "templates/shared/styles"
import EmptyGridItems from "templates/shared/empty-grid-items"
import { StarterCard } from "./starter-card"
// imports to allow the category starters page to be reused
import React from "react"
import StarterCardList from "./starter-card-list"
export const acceptedCategories = [
  "Popular",
  "Headless CMS",
  "Blog",
  "Portfolio",
  "Documentation",
  "Boilerplate",
  "E-commerce",
  "Community",
]

const hasSearchTerm = urlState =>
  urlState.search !== "" ||
  urlState.type.length > 0 ||
  urlState.cms.length > 0 ||
  urlState.feature.length > 0

const EmptyStartersList = ({ emptyStateReason }) => (
  <div
    css={{
      display: `grid`,
      height: `80%`,
      alignItems: `center`,
      justifyContent: `center`,
      textAlign: `center`,
    }}
  >
    <h1>No {emptyStateReason} starters found!</h1>
  </div>
)

const StartersList = ({ urlState, starters, count }) => {
  if (!starters.length) {
    // empty state!
    const emptyStateReason =
      urlState.search !== ``
        ? urlState.search // if theres a search term
        : `matching` // if no search term
    return <EmptyStartersList emptyStateReason={emptyStateReason} />
  }

  if (!count) {
    return null
  }

  // when there is no search term, return the category based starters
  if (!hasSearchTerm(urlState)) {
    starters = starters.sort(sortByGitHubStars)
    return <CategoryStarters starters={starters} />
  }

  starters = starters.sort(sortByGitHubStars).slice(0, count)
  return (
    <div
      sx={{
        display: `flex`,
        flexWrap: `wrap`,
        width: `100%`,
        gap: 7,
        pt: 5,
        px: [0, 3, 5],
      }}
    >
      {starters.map(starter => {
        return <StarterCard key={starter.id} starter={starter} size="M" />
      })}
      {starters.length && <EmptyGridItems styles={showcaseItem} />}
    </div>
  )
}

const starterIsValid = (allCategoryStarters, starter, tag) => {
  // allow for Community starter category to have
  // more than the MAX_NUMBER_OF_STARTERS_PER_CATEGORY
  if (tag === "Community") {
    return !allCategoryStarters[tag].some(
      ({ title }) => title === starter.title
    )
  } else {
    return (
      allCategoryStarters[tag].length < MAX_NUMBER_OF_STARTERS_PER_CATEGORY &&
      !allCategoryStarters[tag].some(({ title }) => title === starter.title)
    )
  }
}
/*
 Takes unorganized starters and places them in categories
 returns an object where each key is a category and values are an array of starters
 (note: only uses the categories mentioned above - acceptedCategories)
*/

const MAX_NUMBER_OF_STARTERS_PER_CATEGORY = 8
export const placeStartersInCategories = starters => {
  return starters.reduce((allCategoryStarters, currentStarter) => {
    // eslint-disable-next-line no-unused-expressions
    currentStarter.starterTags.nodes?.forEach(({ name: tag }) => {
      if (acceptedCategories.includes(tag)) {
        if (!allCategoryStarters[tag]) allCategoryStarters[tag] = []
        if (starterIsValid(allCategoryStarters, currentStarter, tag))
          allCategoryStarters[tag].push(currentStarter)
      }
    })
    return allCategoryStarters
  }, {})
}

export const CategoryStarters = ({ starters }) => {
  const categoryStarters = React.useMemo(
    () => placeStartersInCategories(starters),
    [starters]
  )

  return (
    <div
      sx={{
        boxSizing: `border-box`,
        display: `flex`,
        flexWrap: `wrap`,
        flexDirection: `column`,
        justifyItems: `flex-start`,
        alignItems: `stretch`,
      }}
    >
      {acceptedCategories.map((category, i) => {
        const starters = categoryStarters[category]
        return starters?.length > 0 ? (
          <StarterCardList
            starters={starters}
            heading={category}
            key={`${category}-${i}`}
          />
        ) : null
      })}

      {starters.length && <EmptyGridItems styles={showcaseItem} />}
    </div>
  )
}

export default StartersList

function sortByGitHubStars(nodeA, nodeB) {
  const metricA = get(nodeA, `githubMetaFields.stars`, 0)
  const metricB = get(nodeB, `githubMetaFields.stars`, 0)
  return metricB - metricA
}
