import React, { Component } from "react"
import queryString from "query-string"
import FilteredStarters from "./filtered-starters"
import { Announcement } from "gatsby-interface"

const emptySearchState = {
  search: ``,
  cms: [],
  type: [],
  feature: [],
  sort: `recent`,
}
class StarterLibraryPage extends Component {
  state = emptySearchState

  setUrlState = newState => {
    const finalState = { ...this.state, ...newState }
    this.setState({ ...finalState })
  }

  render() {
    const { location } = this.props
    const urlState = queryString.parse(location.search)
    // if the user looks for the details page for a deprecated starter, a redirect shows this announcement(to remove a starter from the deprecated state, update marketing-redirects.yaml)
    const deprecatedStarter = urlState["deprecated-starter"]
    return (
      <>
        {deprecatedStarter && (
          <Announcement
            css={theme => ({
              color: theme.colors.grey[90],
            })}
          >
            The {deprecatedStarter} has become deprecated, please choose a
            starter from the starters below that are up to date and use best
            practices!
          </Announcement>
        )}
        <FilteredStarters
          {...this.props}
          setURLState={this.setUrlState}
          urlState={this.state}
        />
      </>
    )
  }
}

export default StarterLibraryPage
