/** @jsx jsx */
import { jsx } from "theme-ui"
import { Component } from "react"
import { debounce } from "lodash-es"
import PropTypes from "prop-types"
import { visuallyHiddenCss } from "../../shared/styles/a11y"

import SearchIcon from "../../search-icon"
import { Heading } from "gatsby-interface"

class DebounceInput extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    labelCss: PropTypes.func,
    value: PropTypes.string,
    delay: PropTypes.number,
  }

  static defaultProps = {
    value: ``,
    delay: 250,
  }

  state = {
    inputValue: ``,
  }

  componentDidMount() {
    this.setInputValue(this.props.value)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value)
      this.setInputValue(this.props.value)
  }

  setInputValue = (value = ``) => {
    this.setState({ inputValue: value })
  }

  onChangeInputText = e => {
    this.setInputValue(e.target.value)
    e.persist()
    this.debounceOnChange()
  }

  onChangeValue = () => {
    this.props.onChange(this.state.inputValue)
  }

  debounceOnChange = debounce(this.onChangeValue, this.props.delay)

  render() {
    const { inputValue } = this.state
    return (
      <label
        css={theme => ({
          minWidth: `240px`,
          flexGrow: 3,
          position: `relative`,
          ...(this.props.labelCss && this.props.labelCss(theme)),
        })}
      >
        <Heading as="h2" sx={visuallyHiddenCss}>
          Search starters
        </Heading>
        <input
          {...this.props}
          css={{ minWidth: `240px` }}
          value={inputValue}
          onChange={this.onChangeInputText}
        />
        <SearchIcon />
      </label>
    )
  }
}

export default DebounceInput
