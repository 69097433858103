import React from "react"
import { graphql } from "gatsby"
import Layout from "../../layout"
import SiteHead from "../../head"
import Container from "../../layout/container"
import { SkipNavTarget } from "../../components/shared/components/SkipNav"
import StarterLibrary from "../../components/starters/library/index"

export function Head(props) {
  return (
    <SiteHead
      {...props}
      title="Starters Library"
      description="Get up and running with pre-made Gatsby templates!"
    />
  )
}

export default function StarterPage({ data: { allWpStarter }, location }) {
  return (
    <Layout>
      <Container>
        <main>
          <SkipNavTarget />
          <StarterLibrary data={allWpStarter} location={location} />
        </main>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query StarterPageQuery {
    allWpStarter(
      filter: {
        starterFields: { screenshot: { id: { ne: null } } }
        starterTags: {
          nodes: { elemMatch: { name: { in: ["v4-ga", "Community"] } } }
        }
      }
    ) {
      nodes {
        ...StarterLibraryFields
      }
    }
  }

  fragment StarterLibraryFields on WpStarter {
    id
    title
    starterFields {
      description
      features
      repoUrl
      url
      screenshot {
        id
        gatsbyImage(
          layout: CONSTRAINED
          width: 512
          height: 256
          cropFocus: [TOP, LEFT]
        )
      }
    }
    starterTags {
      nodes {
        id
        name
      }
    }
    githubMetaFields {
      slug
      stub
      name
      description
      stars
      lastUpdated
      owner
      avatar
      githubFullName
      gatsbyMajorVersion
      allDependencies
      gatsbyDependencies
      miscDependencies
    }
  }
`
