/** @jsx jsx */
import { jsx } from "theme-ui"
import * as React from "react"
import { MdArrowDownward } from "react-icons/md"
import { Button, hexToRGBA, Link } from "gatsby-interface"
import StarterList, { acceptedCategories } from "./starter-list"
import DebounceInput from "./DebounceInput"
import { themedInput } from "../../../utils/styles/org"
import { loadMoreButton } from "../../../templates/shared/styles"
import { ContentContainer } from "../../../templates/shared/sidebar"
import StarterHeader from "./starter-header"
import PopularStarters from "./popular-starters"

const DEFAULT_SITES_TO_SHOW = 12

export default function FilteredStarterLibrary({
  data,
  urlState,
  setURLState,
}) {
  const [sitesToShow, setSitesToShow] = React.useState(DEFAULT_SITES_TO_SHOW)

  /*
  Create callbacks for setting filter state
*/

  const onChangeUrlWithText = value => setURLState({ search: value })

  const showMoreSites = starters => {
    const shouldShowAll = sitesToShow + 15 > starters.length
    setSitesToShow(prevCount =>
      shouldShowAll ? starters.length : prevCount + 15
    )
  }

  const isUrlStateEmpty = urlState.search === ``

  let { nodes: starterNodes } = data

  /*
  Filter starters array by active filters
  */

  if (urlState.search.length > 0) {
    starterNodes = starterNodes.filter(node => {
      // limits the need of multiple calls of toLowerCase() - searches the starter node text for a match of the search term
      const searchTermRegex = new RegExp(urlState.search, "i")
      return searchTermRegex.test(JSON.stringify(node))
    })
  }

  const isMoreSitesToShow = sitesToShow < starterNodes.length
  const disabledCursor = isUrlStateEmpty
    ? {}
    : {
        ":hover": {
          cursor: `not-allowed`,
        },
      }
  return (
    <React.Fragment>
      <StarterHeader>
        <DebounceInput
          type="text"
          labelCss={theme => ({
            display: `none`,
            [theme.mediaQueries.tablet]: {
              display: `inline-block`,
              verticalAlign: `top`,
            },
          })}
          sx={theme => ({
            ...themedInput(theme),

            justifyContent: `space-between`,
            paddingLeft: theme.space[8],
            width: "calc(100vw - 550px)",
            [theme.mediaQueries.desktop]: {
              width: 450,
              "&:focus": {
                width: 450,
              },
            },
          })}
          value={urlState.search}
          onChange={onChangeUrlWithText}
          placeholder={`Starter title or description`}
          aria-label={`Starter title or description`}
        />
        <Link
          to="/docs/how-to/local-development/starters/"
          variant={"DEFAULT"}
          css={theme => ({
            marginLeft: theme.space[0],
            lineHeight: `36px`, // align with search box
            verticalAlign: `top`,
            [theme.mediaQueries.phablet]: {
              marginLeft: theme.space[4],
            },
          })}
        >
          <span
            css={theme => ({
              display: `none`,
              [theme.mediaQueries.phablet]: {
                display: `inline`,
              },
            })}
          >
            Starter&nbsp;
          </span>
          Docs →
        </Link>
      </StarterHeader>
      <section className="showcase" css={{ display: `flex` }}>
        <ContentContainer>
          <div
            sx={_ => ({
              backgroundColor: theme =>
                hexToRGBA(theme.colors.white, 0.985, true),
              alignItems: `start`,
              zIndex: 1,
              position: `sticky`,
              top: `0`,
            })}
          >
            <div
              css={theme => ({
                marginLeft: `-${theme.space[5]}`,
                paddingLeft: theme.space[5],
              })}
            >
              {acceptedCategories.map((category, i) => (
                <span key={category}>
                  <a
                    css={theme => ({
                      fontSize: theme.fontSizes[2],
                      textDecoration: `none`,
                      whiteSpace: `nowrap`,
                      width: `fit-content`,
                      display: `inline-block`,
                      padding: theme.space[2],
                      color: theme.colors.grey[60],
                      boxSizing: `border-box`,
                      ...disabledCursor,
                    })}
                    href={`#${category.replace(" ", "-")}`}
                    onClick={event => {
                      // prevents url from changing when disabled
                      if (!isUrlStateEmpty) {
                        event.preventDefault()
                      }
                    }}
                  >
                    {category}
                  </a>
                  {i === acceptedCategories.length - 1 ? null : ` / `}
                </span>
              ))}
            </div>
            <Link
              to={`/starters/submissions`}
              variant="DEFAULT"
              css={theme => ({
                marginTop: theme.space[2],
                marginLeft: theme.space[2],
              })}
            >{`Submit your starter →`}</Link>
          </div>
          {isUrlStateEmpty ? <PopularStarters data={data} /> : null}

          <StarterList
            urlState={urlState}
            sortRecent={urlState.sort === `recent`}
            starters={starterNodes}
            count={sitesToShow}
          />
          {isMoreSitesToShow && !isUrlStateEmpty ? (
            <Button
              size="L"
              sx={loadMoreButton}
              onClick={() => showMoreSites(starterNodes)}
              rightIcon={<MdArrowDownward />}
            >
              Load More
            </Button>
          ) : null}
          <br />
        </ContentContainer>
      </section>
    </React.Fragment>
  )
}
