/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { MdSearch } from "react-icons/md"

const SearchIcon = ({ focused, style }) => (
  <MdSearch
    focusable="false"
    aria-hidden="true"
    sx={{
      width: t => t.space[6],
      height: t => t.space[6],
      position: `absolute`,
      left: `0.5rem`,
      top: `50%`,
      pointerEvents: `none`,
      transform: `translateY(-50%)`,
      fill: t => (focused ? t.colors.grey[60] : t.colors.grey[50]),
      ...style,
    }}
  />
)

SearchIcon.propTypes = {
  overrideCSS: PropTypes.object,
}

export default SearchIcon
