import * as React from "react"
import { Heading } from "gatsby-interface"

const sectionCss = theme => ({
  paddingTop: theme.space[7],
  paddingBottom: theme.space[7],
})

const headingsCss = _ => ({
  display: `inherit`,
})

const StarterHeader = ({ children }) => {
  return (
    <section css={sectionCss}>
      <div>
        <div css={headingsCss}>
          <Heading
            as="h1"
            css={theme => [
              {
                paddingBottom: 0,
                marginBottom: 0,
                marginRight: theme.space[8],
                display: `inline`,
              },
            ]}
          >
            Gatsby Starter Library
          </Heading>
          {children}
        </div>
      </div>
    </section>
  )
}

export default StarterHeader

/*

*/
